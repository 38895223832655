<template>
  <div class="content_block">
    <div class="top">
      <b>开票信息</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="纳税人性质" prop="taxpayerNature">
              <el-select v-model.trim="form.taxpayerNature" placeholder="请选择纳税人性质">
                <el-option label="一般纳税人" value="一般纳税人"></el-option>
                <el-option label="小规模纳税人" value="小规模纳税人"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业名称" prop="tenantName">
              <el-input placeholder="请输入企业名称" v-model.trim="form.tenantName" readonly ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="税号" prop="dutyParagraph">
              <el-input placeholder="请输入税号" v-model.trim="form.dutyParagraph" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开票电话" prop="invoicingPhoneNumber">
              <el-input placeholder="请输入开票电话" v-model.trim="form.invoicingPhoneNumber" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户行" prop="openingBankId">
              <OpeningBankSelector v-model.trim="form.openingBankId" :name.sync="form.openingBankName" placeholder="请选择开户行" ></OpeningBankSelector>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所在地区" prop="invoiceProvinceId">
              <area-cascader
                :provinceId.sync="form.invoiceProvinceId"
                :provinceName.sync="form.invoiceProvinceName"
                :cityId.sync="form.invoiceCityId"
                :cityName.sync="form.invoiceCityName"
                placeholder="请选择所在地区"
                :areaId.sync="form.invoiceAreaId"
                :areaName.sync="form.invoiceAreaName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="invoiceAddrs">
              <el-input placeholder="请输入详细地址" v-model.trim="form.invoiceAddrs"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" >
            <el-form-item label="证明材料"  prop='tenantFileReqDTOs' class="fileContainer">
              <template class="labelContainer" slot="label">
                <span>证明材料</span><br/>
                <a @click="handleClick">查看说明</a>
              </template>
              <Upload
                v-model="form.tenantFileReqDTOs"
                label="证明材料"
                buttonText="上传"
                type="single"
                :show-file-list="false"
                @success="handleFileLists"
                @remove="handleRemove"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :maxSize="10"
                size="origin"
                :limit="10"
              >
              </Upload>
            </el-form-item>
          </el-col>
          <el-col :span="24"  v-if="form.tenantFileReqDTOs&&form.tenantFileReqDTOs.length">
            <el-form-item label="">
              <ul class="imgContainer">
                <li v-for="(img,index) in form.tenantFileReqDTOs" :key="img.url">
                  <div class="left">
                    <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title='img.name'><img :src="require('@/assets/pdf.svg')" />{{ img.name }}</span>
                  </el-link>
                  </div>
                  <div class="right">
                    <i class="el-icon-success" style="color:#67C23A;margin-right: 8px;font-size:16px;"></i>
                    <i class="el-icon-delete" style="color:#409EFF;font-size:16px;cursor: pointer;" @click="handleDelete(index)"></i>
                  </div>
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <el-descriptions :colon="false" :column="2" style="margin-top: 10px;" v-else>
      <el-descriptions-item label="纳税人性质" labelClassName="warningLabel" contentClassName="warningContent">{{ form.taxpayerNature }}</el-descriptions-item>
      <el-descriptions-item label="企业名称" labelClassName="warningLabel" contentClassName="warningContent">{{ form.tenantName }}</el-descriptions-item>
      <el-descriptions-item label="税号" labelClassName="warningLabel" contentClassName="warningContent">{{ form.dutyParagraph }}</el-descriptions-item>
      <el-descriptions-item label="开票电话" labelClassName="warningLabel" contentClassName="warningContent">{{ form.invoicingPhoneNumber }}</el-descriptions-item>
      <el-descriptions-item label="开户行" labelClassName="warningLabel" contentClassName="warningContent">{{ form.openingBankName }}</el-descriptions-item>
      <el-descriptions-item label="银行账户" labelClassName="warningLabel" contentClassName="warningContent">{{ form.bankAccount }}</el-descriptions-item>
      <el-descriptions-item label="所在地区" :span="2" labelClassName="warningLabel" contentClassName="warningContent">{{ form.invoiceProvinceName }}{{ form.invoiceCityName }}{{ form.invoiceAreaName }}</el-descriptions-item>
      <el-descriptions-item label="详细地址" :span="2" labelClassName="warningLabel" contentClassName="warningContent">{{ form.invoiceAddrs }}</el-descriptions-item>
      <el-descriptions-item label="附件信息" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color:#FF5959;margin-right:4px;"></i><el-link type="primary" :href="img.url" :underline="false" target="blank"> {{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-dialog title="证明材料说明"
      :close-on-click-modal="false"
      @close="isShow=false"
      :visible="isShow"
      width="520px"
    >
      <div class="infoContainer">
        <p>以下四点证明，满足一点即可：</p>
        <p>1、一般企业在申请一般纳税人资格时税务机关会返回的《一般纳税人认定表》或《税务事项通知书》，受理通知书需有生效日期及税务局印章；</p>
        <p>2、加盖了“一般纳税人” 印章的税务登记证副本(也就是营业执照)；</p>
        <p>3、当地税务局开具的一般纳税人证明，并加盖税务局的章；</p>
        <p>4、客户登录当地税务局网站，查询到自己为一般纳税人截图证明，要求右上角公司名称要完整；</p>
      </div>
      <div style="text-align:right">
        <el-button @click="isShow=false" type="primary" size="mini">我知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AreaCascader from '@/components/common/areaCascader';
import OpeningBankSelector from '@/components/openingBankSelector';
export default {
  name: '',
  components: {
    AreaCascader,
    OpeningBankSelector,
    Upload: ()=>import('@/components/common/upload'),
  },
  props: {
    invoiceInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    type: {
      type: String,
      default: '15',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'InvoicingInformation') {
          let invoiceInfo = JSON.parse(JSON.stringify(this.invoiceInfo));
          this.form = invoiceInfo || {
            taxpayerNature: '',
            tenantName: '',
            dutyParagraph: '',
            bank: '',
            invoicingPhoneNumber: '',
            invoiceProvinceId: '',
            invoiceProvinceName: '',
            invoiceCityId: '',
            invoiceCityName: '',
            invoiceAreaId: '',
            invoiceAreaName: '',
            invoiceAddrs: '',
            tenantFileReqDTOs: [],
            openingBankId: '',
            openingBankName: '',
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    invoiceInfo: {
      handler (val) {
        if (val) {
          val = JSON.parse(JSON.stringify(val));
          this.form = val || {
            taxpayerNature: '',
            tenantName: '',
            dutyParagraph: '',
            bank: '',
            invoicingPhoneNumber: '',
            invoiceProvinceId: '',
            invoiceProvinceName: '',
            invoiceCityId: '',
            invoiceCityName: '',
            invoiceAreaId: '',
            invoiceAreaName: '',
            invoiceAddrs: '',
            tenantFileReqDTOs: [],
            openingBankId: '',
            openingBankName: '',
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleRemove () {
      console.log(arguments[0]);
    },
    handleFileLists () {
      console.log(arguments[0]);
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let invoiceInfo = this.invoiceInfo ? JSON.parse(JSON.stringify(this.invoiceInfo)) : null;
      this.form = invoiceInfo || {
        taxpayerNature: '',
        tenantName: '',
        dutyParagraph: '',
        bank: '',
        invoicingPhoneNumber: '',
        invoiceProvinceId: '',
        invoiceProvinceName: '',
        invoiceCityId: '',
        invoiceCityName: '',
        invoiceAreaId: '',
        invoiceAreaName: '',
        invoiceAddrs: '',
        tenantFileReqDTOs: [],
        openingBankId: '',
        openingBankName: '',
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateInvoice', {...this.form, tenantFileReqDTOs});
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateInvoice', {...this.form, tenantFileReqDTOs});
        }
      });
    },
    handleClick () {
      this.isShow = true;
    },
  },
  data () {
    return {
      isEditing: false,
      isShow: false,
      form: {
        taxpayerNature: '',
        tenantName: '',
        dutyParagraph: '',
        bank: '',
        invoicingPhoneNumber: '',
        invoiceProvinceId: '',
        invoiceProvinceName: '',
        invoiceCityId: '',
        invoiceCityName: '',
        invoiceAreaId: '',
        invoiceAreaName: '',
        invoiceAddrs: '',
        tenantFileReqDTOs: [],
        openingBankId: '',
        openingBankName: '',
      },
      rules: {
        taxpayerNature: [
          {
            required: true,
            message: '请选择纳税人行政',
            trigger: ['blur', 'change'],
          },
        ],
        tenantName: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: ['blur', 'change'],
          },
        ],
        dutyParagraph: [
          {
            required: true,
            message: '请输入税号',
            trigger: ['blur', 'change'],
          },
        ],
        invoicingPhoneNumber: [
          {
            required: true,
            message: '请输入开票电话',
            trigger: ['blur', 'change'],
          },
        ],
        bank: [
          {
            required: true,
            message: '请选择开户行',
            trigger: ['blur', 'change'],
          },
        ],
        tenantFileReqDTOs: [
          {
            required: true,
            message: '请选择证明材料',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 85px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
.imgContainer{
  list-style: none;
  li{
    height: 40px;
    line-height: 20px;
    background: #F5F7FA;
    border-radius: 4px;
    display: flex;
    width:500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left{
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width:440px;
        display:inline-block;
        span.el-link--inner{
          width: 100%;
          span.name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 420px;
            display:inline-block;
          }
        }
      }
    }
    img{
      width:16px;
      height:16px;
      margin-right: 4px;
    }
  }
}
.el-form-item--small.fileContainer > .el-form-item__label {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  span{
    flex:1;
  }
  a{
    font-size: 12px;
  }
}
.infoContainer {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  padding: 10px 22px;
  line-height: 20px;
  p{
    margin-bottom: 0;
  }
}
</style>
